//import './bootstrap';
//import 'tw-elements';

// import Swiper bundle with all modules installed
//import Swiper from 'swiper/bundle';
import Swiper, {Navigation, Pagination} from 'swiper';
import { Carousel } from 'bootstrap'
Swiper.use([Pagination]);

window.Swiper = Swiper;


import Cookies from "js-cookie";

window.Cookies = Cookies;

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse)
Livewire.start()
